import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import { ImHome } from "react-icons/im"
import { graphql, useStaticQuery, Link } from "gatsby"
import Social from "../components/Social"
import { IoRocketOutline } from "react-icons/io5"
import SubscribeForm from "../components/SubscribeForm"

const ServicesPage = ({location}) => {
    const {servicespage} = useStaticQuery(graphql`
    query {
        servicespage: file(relativePath: {eq: "page/web-design-services/index.md"}) {
          childMarkdownRemark {
            html
            frontmatter {
              date(formatString: "DD MMMM, YYYY")
              datemod(formatString: "DD MMMM, YYYY")
              description
              featureimagealt
              seometadesc
              seotitle
              title
              featureimage {
                extension
                publicURL
              }
            }
          }
        }
      }      
    `)
    const servicesdata = servicespage.childMarkdownRemark
    const frontmatter = servicesdata.frontmatter
    return (
        <> 
            <Layout location={location}>
                <SEO title={frontmatter.seotitle} description={frontmatter.seometadesc} image={frontmatter.featureimage.publicURL} />{/* title, description, image, article */}
                <PageHero heading={frontmatter.title} />
                <section className="container mx-auto max-w-7xl text-center pt-4">
                  <div className="px-8 pt-4">
                      <ul className="text-left font-mono text-xs md:text-base">
                        <li className="inline-block border-black border-r-2">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-2">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block">
                          <span className="px-2">{frontmatter.title}</span>
                        </li>
                      </ul>
                      </div>
                      <div className="flex flex-col lg:flex-row">
                          <div className="p-6 lg:w-2/3">
                              <div className="cmscontent" dangerouslySetInnerHTML={{ __html: servicesdata.html}}/>
                          </div>
                          <div className="px-6 pt-6 pb-0 lg:pb-6 w-2/3 lg:w-1/3 flex flex-row lg:flex-col items-start">
                              <div className="lg:sticky top-24">
                                  <div className="text-base py-0 lg:py-2 pl-2 lg:pl-0 text-left">
                                      <div className="inline-flex items-center"><IoRocketOutline /><p>-Rokit Media</p></div>
                                      <p>Updated: {frontmatter.datemod}</p>
                                  </div>
                                  <p className="text-xs hidden lg:inline-block text-left">Rokit Media is a modern digital web design and development agency based in Bishop's Stortford within the Herts and Essex region of the United Kingdom.</p>
                                  <p className="text-xs hidden lg:inline-block text-left">We build Websites and Web Apps the modern way, which delivers blazing fast performance, higher security and cheaper to run.</p>
                                  <h3 className="hidden lg:inline-block pulse heading-box-text2 text-2xl pt-4 mx-8">
                                      <span>it's good to share!</span>
                                  </h3>
                                  <div className="hidden lg:inline-block">
                                    <Social location={location} notitle={true} />
                                  </div>
                                  <div className="hidden lg:inline-block">
                                      <SubscribeForm formid="subscribe-form-1" />
                                  </div>
                              </div>
                          </div>
                          <div className="flex flex-col justify-center lg:hidden">
                            <h3 className="pulse heading-box-text2 text-2xl pt-6 mx-8">
                              <span>it's good to share!</span>
                            </h3>
                              <Social location={location} notitle={true} />
                              <SubscribeForm formid="subscribe-form-2" />
                          </div>
                      </div>
                </section>
            </Layout>
        </>
    )
}

export default ServicesPage